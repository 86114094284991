<template>
  <div class="table-tab" :dir="$direction">
    <div class="tab-text">
      <slot></slot>
    </div>
    <svg class="diagonal" width="30px" height="39px" viewBox="0 0 20 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5764 0.000143058L20 0.000488274V32H1L8.71023 2.97325C9.17585 1.22032 10.7626 5.87155e-05 12.5764 0.000143058Z"
        fill="white"
      />
      <path d="M20 0.500488H12.5637C10.7559 0.500488 9.1728 1.71302 8.70183 3.45838L1 32" stroke="#e5e8ea" />
    </svg>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  setup() {},
};
</script>
<style lang="scss" scoped>
.table-tab {
  display: flex;
  font-weight: 400;
  font-size: 14px;
  position: sticky;
  right: 0rem;
  height: 39px;
  .diagonal {
    margin-inline-start: -4px;
    [dir='ltr'] & {
      transform: scaleX(-1);
    }
  }

  .tab-text {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    padding-inline-start: 1rem;
    border-radius: 0rem 0.25rem 0rem 0rem;
    border-top: 1px solid #e5e8ea;
    border-inline-start: 1px solid #e5e8ea;
  }
}
</style>
