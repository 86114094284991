<template>
  <!-- eslint-disable -->
  <div v-if="formData.supplierId">
    <div class="agreement-record-body">
      <div class="row d-flex justify-content-between p-4 g-0 agreement-record-body__header-above-table">
        <p>{{ $t('products') }}</p>
        <div>
          <el-dropdown trigger="click" class="mx-2" @command="handleCommand">
            <el-button size="mini" icon="el-icon-more" />
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="DROPDOWN_COMMANDS.RESET_SUPPLIER_PRODUCTS"
                >{{ $t('agreementRecording.resetFormWithSupplierProducts') }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button
            :class="'agreement-record-body__transparent_button agreement-record-body__view_consequences_button'"
            size="mini"
          >
            <i class="el-icon-full-screen" />
            {{ $t('viewConsequences') }}
          </el-button>
        </div>
      </div>
      <template>
        <el-table
          :data="termsInCurrentPage"
          highlight-current-row
          :span-method="objectSpanMethod"
          :header-cell-style="{
            background: 'linear-gradient(0deg, #F8FAFB, #F8FAFB), #F2F5F7',
            padding: 0,
            color: '#1F284D',
            'font-weight': 400,
          }"
          :cell-style="{ padding: '0.01em 0 0.01em 0' }"
          size="mini"
        >
          <el-table-column type="index" :index="pageStartingIndex + 1" min-width="10" label="#"></el-table-column>
          <el-table-column width="25" class="table-th">
            <template #default="{ row: termRow }">
              <template v-if="!!hasOverlap(termRow)">
                <el-tooltip :content="currentTermRange(termRow)" placement="top" effect="dark">
                  <NoticeIcon width="16" class="text-warning" />
                </el-tooltip>
              </template>
            </template>
          </el-table-column>

          <el-table-column width="150" :label="$t('sku')" class="table-th">
            <template #default="{ row: termRow, $index }">
              <el-form-item :prop="`terms.${pageStartingIndex + $index}.sku`">
                <el-input
                  v-model="termRow.sku"
                  :data-field-name="`terms.${pageStartingIndex + $index}.sku`"
                  clearable
                  :placeholder="$t('sku')"
                  size="mini"
                  @blur="updateProductOnSkuChange(termRow)"
                />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column min-width="250" :label="$t('name')">
            <template #default="{ row: termRow, $index }">
              <el-form-item :prop="`terms.${pageStartingIndex + $index}.name`" required>
                <el-select
                  v-model="termRow.name"
                  :data-field-name="`terms.${pageStartingIndex + $index}.name`"
                  filterable
                  clearable
                  autocomplete="true"
                  :placeholder="$t('select')"
                  size="mini"
                  class="w-100"
                  @change="updateSkuOnProductNameChange(termRow)"
                >
                  <el-option
                    v-for="supplierItem in supplierItems"
                    :key="supplierItem._id"
                    :label="supplierItem.name"
                    :value="supplierItem.name"
                  />
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column min-width="105">
            <template #header>
              <el-popover v-model="batchNewPricingPopoverOpen" trigger="click" placement="bottom-end">
                <agreement-form-pricing
                  :price-lists="priceLists"
                  @pricingMethodFormSubmit="pricingMethodBatchFormSubmit($event)"
                />
                <el-button slot="reference" :class="'agreement-record-body__transparent_button'" size="mini">
                  {{ $t('newPricing') }}
                  <i class="el-icon-s-tools" />
                </el-button>
              </el-popover>
            </template>
            <template #default="{ row: termRow, $index }">
              <template v-if="termRow.newPricing.pricingMethod === pricingMethods.FIXED_PRICE">
                <el-form-item
                  :prop="`terms.${pageStartingIndex + $index}.newPricing.price`"
                  :rules="{
                    type: 'number',
                    required: true,
                    min: 0,
                    message: 'Price should be a positive number',
                    trigger: 'change',
                  }"
                >
                  <input-money
                    v-model="termRow.newPricing.price"
                    size="mini"
                    :data-field-name="`terms.${pageStartingIndex + $index}.newPricing.price`"
                  />
                </el-form-item>
              </template>
              <template v-if="termRow.newPricing.pricingMethod === pricingMethods.PRICE_LIST">
                <el-form-item :prop="`terms.${pageStartingIndex + $index}.newPricing.priceListId`" required>
                  <div v-if="!priceListsLoading" class="price-list-name">
                    {{ getPriceList(termRow.newPricing.priceListId).name }}
                  </div>
                </el-form-item>
              </template>
              <template v-else-if="termRow.newPricing.pricingMethod === pricingMethods.PRICE_INDEX">
                <term-display :term="termRow.newPricing" />
              </template>
            </template>
          </el-table-column>
          <el-table-column min-width="60">
            <template #default="{ row: termRow }">
              <el-form-item>
                <el-popover v-model="termRow.newPricingPopover" placement="bottom-end">
                  <agreement-form-pricing
                    v-if="termRow.newPricingPopover"
                    :initial-data="termRow.newPricing"
                    :price-lists="priceLists"
                    @pricingMethodFormSubmit="pricingMethodFormSubmit($event, termRow)"
                  />
                  <el-button
                    slot="reference"
                    type="text"
                    class="agreement-record-body__new-promotion-pricing-popover-button"
                  >
                    {{ pricingMethodToText(termRow.newPricing) }}
                  </el-button>
                </el-popover>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column min-width="100" :label="$t('newPromotion')">
            <template #default="{ row: termRow, $index }">
              <el-form-item :prop="`terms.${pageStartingIndex + $index}.newPromotion.rewardValue`">
                <el-input
                  v-model.number="termRow.newPromotion.rewardValue"
                  type="number"
                  size="mini"
                  :data-field-name="`terms.${pageStartingIndex + $index}.newPromotion.rewardValue`"
                ></el-input>
                <el-popover></el-popover>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column min-width="65">
            <template #default="{ row: termRow }">
              <el-form-item>
                <el-button
                  type="text"
                  class="agreement-record-body__new-promotion-pricing-popover-button agreement-record-body__transparent_button"
                >
                  {{ $t(termRow.newPromotion.rewardType) }}
                </el-button>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column min-width="95" :label="$t('currentPricing')">
            <template #default="{ row: termRow, $index }">
              <el-form-item>
                <template v-if="termRow.productId">
                  <term-display
                    v-if="currentPricingTerm(termRow.productId)"
                    :term="currentPricingTerm(termRow.productId)"
                  />
                </template>
                <template v-else>
                  <div class="w-100 d-flex justify-content-between align-items-center">
                    <Button
                      type="primary"
                      :class="'agreement-record-body__new-product-button'"
                      @click="createItemIndex = pageStartingIndex + $index"
                    >
                      <PlusIcon />{{ $t('newProduct') }}
                    </Button>
                  </div>
                </template>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column min-width="115" :label="$t('currentPromotion')">
            <template #default="{ row: termRow }">
              <el-form-item>
                <template v-if="termRow.productId">
                  <term-display
                    v-if="currentDiscountTerm(termRow.productId)"
                    :term="currentDiscountTerm(termRow.productId)"
                  />
                </template>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column v-slot="{ $index }" min-width="35">
            <el-button
              class="agreement-record-body__transparent_button"
              size="small"
              icon="el-icon-close"
              @click="formData.terms.splice(pageStartingIndex + $index, 1)"
            />
          </el-table-column>
          <el-table-column v-slot="{ row: term }" width="40" align="center" class-name="options">
            <el-dropdown trigger="click" @command="onProductInfo">
              <el-button type="text"><i class="co-kebab" /></el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item :command="term.productId" :disabled="!Boolean(term.productId)">{{
                    $t('productsModule.productInfo')
                  }}</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </el-table-column>
        </el-table>
      </template>
    </div>
    <div class="w-100">
      <div class="d-flex justify-content-between w-100 mt-2">
        <el-button @click="addNewRow"> + {{ $t('row') }} </el-button>
        <el-pagination
          layout="prev, pager, next"
          small
          background
          :total="formData.terms.length"
          :page-size="termsPageSize"
          :current-page.sync="currentTermPage"
        />
        <div :style="{ width: '83px' }"></div>
      </div>
    </div>
    <el-dialog
      v-if="itemFormVisible"
      :visible.sync="itemFormVisible"
      :title="$t('AgreementRecording.AgreementFormBody.newProduct')"
    >
      <ProductForm :product="itemFormDefaults" :business-products="supplierProducts" @submit="onProductFormSubmit" />
    </el-dialog>
    <SimilarProductPrompt
      v-if="similarProducts.length"
      :new-product="productToCreate"
      :similar-products="similarProducts"
      @switch="switchProduct"
      @create="createProduct"
      @close="dismissModals"
    />
  </div>
</template>

<script>
/* eslint-disable */
import leven from 'leven';
import { computed } from 'vue';

import AgreementFormPricing from '@/modules/purchase-management/components/agreements/AgreementFormPricing';
import { PRICING_METHODS_ENUM } from '@/modules/suppliers/models/term';
import ProductForm from '@/modules/products/components/ProductForm';
import { openProductModal } from '@/modules/products/store/product-modal';
import { createProduct } from '@/modules/products/compositions/products-operations';
import { NoticeIcon, PlusIcon } from '@/assets/icons';
import { Button } from '@/modules/core';

import { formatMoney, formatDate } from '../../purchaseManagementFormatters';
import SimilarProductPrompt from '../SimilarProductPrompt.vue';
import { usePriceLists } from '../../compositions/priceLists';

const getScore = (text1, text2) => leven(text1, text2) / Math.max(text1.length, text2.length);

const DROPDOWN_COMMANDS = {
  RESET_SUPPLIER_PRODUCTS: 'resetSupplierProducts',
};

export default {
  name: 'AgreementRecordBodyForm',
  components: { AgreementFormPricing, ProductForm, SimilarProductPrompt, NoticeIcon, PlusIcon, Button },
  props: {
    currentTerms: { type: Array, default: () => [] },
    conflictingTerms: { type: Array, default: () => [] },
    supplierItems: { type: Array, default: () => [] },
    formData: { type: Object, default: () => ({ supplierId: '', terms: [] }) },
    termsPageSize: { type: Number, default: 25 },
  },
  setup(props) {
    const { priceLists, loading: priceListsLoading } = usePriceLists(computed(() => props.formData.supplierId));
    return {
      priceLists,
      priceListsLoading,
      formatMoney,
      formatDate,
    };
  },
  data() {
    return {
      currentTermPageIndex: 0,
      activeRow: -1,
      createItemIndex: null,
      currentPagePricingMethodSelected: PRICING_METHODS_ENUM.FIXED_PRICE,
      similarProducts: [],
      productToCreate: null,
      batchNewPricingPopoverOpen: false,
    };
  },
  computed: {
    DROPDOWN_COMMANDS: () => DROPDOWN_COMMANDS,
    priceIndexes() {
      return this.$store.state.collections.priceIndexes;
    },
    priceIndexesById() {
      return this.priceIndexes.reduce(
        (acc, currentPriceIndex) => ({ ...acc, [currentPriceIndex._id]: currentPriceIndex }),
        {}
      );
    },
    itemFormVisible: {
      get() {
        return this.createItemIndex !== null && !this.productToCreate;
      },
      set(value) {
        if (!value) {
          this.dismissModals();
        }
      },
    },
    itemFormDefaults() {
      if (!this.itemFormVisible) return {};
      const item = this.formData.terms[this.createItemIndex];
      return {
        sku: item.sku,
        name: item.name,
      };
    },
    pageStartingIndex() {
      return this.currentTermPageIndex * this.termsPageSize;
    },
    termsInCurrentPage() {
      return this.formData.terms.slice(
        this.currentTermPageIndex * this.termsPageSize,
        this.currentTermPageIndex * this.termsPageSize + this.termsPageSize
      );
    },
    pricingMethods() {
      return PRICING_METHODS_ENUM;
    },
    currentTermPage: {
      get() {
        return this.currentTermPageIndex + 1;
      },
      set(index) {
        this.currentTermPageIndex = index - 1;
      },
    },
    supplierProducts() {
      return this.supplierItems.map((item) => ({
        id: item.id,
        name: item.name,
        sku: item.code,
        businessId: item.supplierId,
      }));
    },
  },
  methods: {
    currentTermRange(termRow) {
      const itemCurrentTerm = this.conflictingTerms.find(
        (term) => term.productId === termRow.productId && term.type === 'pricing'
      );
      const fromDate = itemCurrentTerm?.fromDate;
      const toDate = itemCurrentTerm?.toDate;
      return this.$t('AgreementRecording.AgreementFormBody.conflictTermTooltip', {
        fromDate: this.formatDate(fromDate),
        toDate: toDate ? this.formatDate(toDate) : this.$t('AgreementRecording.AgreementFormBody.newAgreement'),
      });
    },
    hasOverlap(termRow) {
      return this.conflictingTerms.some((term) => term.productId === termRow.productId);
    },
    getPriceList(priceListId) {
      return this.priceLists.find(({ id }) => id === priceListId);
    },
    onProductInfo(productId) {
      openProductModal(productId);
    },
    updateWithCreatedProduct(createdProduct) {
      const term = this.formData.terms[this.createItemIndex];
      term.name = createdProduct.name;
      term.productId = createdProduct.id;
      term.sku = createdProduct.sku;
    },
    pricingMethodToText(pricing) {
      let text = '';
      if (pricing?.pricingMethod === PRICING_METHODS_ENUM.PRICE_INDEX) {
        text = this.priceIndexesById[pricing.priceIndexId]
          ? this.priceIndexesById[pricing.priceIndexId].name
          : this.$t(pricing.pricingMethod);
      } else {
        text = this.$t(pricing?.pricingMethod);
      }
      return text;
    },
    pricingMethodBatchFormSubmit(newPricingForm) {
      this.batchNewPricingPopoverOpen = false;
      this.formData.terms.forEach((term) => (term.newPricing = newPricingForm));
    },
    addNewRow() {
      this.formData.terms.push({
        sku: '',
        name: '',
        productId: '',
        newPricingPopover: false,
        newPricing: {
          type: 'pricing',
          price: undefined,
          pricingMethod: this.currentPagePricingMethodSelected,
        },
        newPromotion: {
          type: 'promotion',
          rewardValue: null,
          rewardType: 'discount',
          rewardRealizationTime: 'onBill',
        },
      });
    },
    currentPricingTerm(productId) {
      if (!productId) return;
      return this.currentTerms.find((term) => term.productId === productId && term.type === 'pricing');
    },
    currentDiscountTerm(productId) {
      if (!productId) return;
      return this.currentTerms.find(
        (term) => term.productId === productId && term.type === 'promotion' && term.rewardType === 'discount'
      );
    },
    updateSkuOnProductNameChange(term) {
      const productName = term.name;

      if (!productName) {
        term.productId = '';
        term.sku = '';
      } else {
        const item = this.supplierItems.find((supplierItem) => supplierItem.name === productName);
        term.productId = item ? item._id : '';
        term.sku = item ? item.code : term.sku;
      }
    },
    updateProductOnSkuChange(term) {
      const productSku = term.sku;

      if (!productSku) {
        term.productId = '';
        term.name = '';
      } else {
        const item = this.supplierItems.find((supplierItem) => supplierItem.code === productSku);
        term.productId = item ? item._id : '';
        term.name = item ? item.name : term.name;
      }
    },
    pricingMethodFormSubmit(newPricingForm, termRow) {
      termRow.newPricingPopover = false;
      termRow.newPricing = newPricingForm;
    },
    objectSpanMethod({ row, columnIndex }) {
      if (!row.productId) {
        if (columnIndex === 8) {
          return {
            rowspan: 1,
            colspan: 2,
          };
        }
        if (columnIndex === 9) {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },
    onProductFormSubmit(data) {
      const productToCreate = { ...data, businessId: this.formData.supplierId };
      const similarNamedProducts = this.supplierProducts.filter(
        ({ name }) => getScore(name, productToCreate.name) <= 0.3
      );

      const similarProducts = this.supplierProducts.filter((product) => {
        if (product.sku && product.sku === productToCreate.sku) return true;
        return similarNamedProducts.includes(product);
      });

      if (similarProducts.length) {
        this.productToCreate = productToCreate;
        this.similarProducts = similarProducts;
      } else {
        this.createProduct(productToCreate);
      }
    },
    switchProduct(product) {
      this.updateWithCreatedProduct(product);
      this.dismissModals();
    },
    async createProduct(productToCreate) {
      const loading = this.$loading();
      try {
        const product = await createProduct(productToCreate);
        this.updateWithCreatedProduct(product);
        this.dismissModals();
      } catch {
        this.$message.error(this.$t('errors.action'));
      }
      loading.close();
    },
    dismissModals() {
      this.similarProducts = [];
      this.productToCreate = null;
      this.createItemIndex = null;
    },
    handleCommand(cmd) {
      switch (cmd) {
        case DROPDOWN_COMMANDS.RESET_SUPPLIER_PRODUCTS:
          this.formData.terms = this.supplierItems.map((product) => ({
            productId: product.id,
            name: product.references[0].name,
            sku: product.references[0].sku,
            newPricingPopover: false,
            newPricing: {
              type: 'pricing',
              price: undefined,
              pricingMethod: this.currentPagePricingMethodSelected,
            },
            newPromotion: {
              type: 'promotion',
              rewardValue: null,
              rewardType: 'discount',
              rewardRealizationTime: 'onBill',
            },
          }));
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.price-list-name {
  line-height: 1.5;
}

.agreement-record-body {
  border: 1px solid #e5e8ea;
  border-radius: 0.3em;
  border-bottom: 0;

  &__view_consequences_button {
    color: #3661dd;
    padding: 0;
    font-weight: 500;
    font-size: 14px;
  }

  &__header-above-table {
    border-bottom: 1px solid #e5e8ea;

    & p {
      font-weight: 500;
      font-size: 14px;
      margin: 0;
    }
  }

  &__new-promotion-pricing-popover-button {
    color: #8c8ca1;
    font-weight: 400;
    font-size: 12px;
  }

  .current-row .agreement-record-body__new-promotion-pricing-popover-button {
    color: #3661dd;
  }

  &__new-pricing_button {
    padding: 0;
    color: #1f284d;
    font-weight: 400;
  }

  .el-form-item {
    margin-bottom: 0;
  }

  &__new-product-button {
    min-width: 1em;
    padding: 0.3em 2em 0.3em 2em;
  }

  &__transparent_button {
    background-color: inherit;
    border: 0;

    &:hover {
      background-color: inherit;
    }
    &:focus {
      outline: 0;
    }
  }
}

::v-deep .options .cell {
  visibility: hidden;

  & .el-button--text {
    color: inherit;
  }
}

::v-deep .el-table__row:hover .options .cell {
  visibility: visible;
}
</style>
